<template>
    <div class="auth flex-grow">
        <a-form-model
            ref="authForm"
            :model="form"
            :rules="authRules"
            layout="vertical"
            class="form">
            <div class="form_inner">
                <h2 class="form_title mb-2 text-center">
                    Авторизация на портале
                </h2>
                <div class="text-base text-center mb-8">
                    Еще нет учетной записи? 
                    <span 
                        @click="openRegistrationDrawer" 
                        class="blue_color cursor-pointer">
                        Зарегистрируйтесь
                    </span>
                </div>
                <a-form-model-item
                    class="mb-2"
                    prop="email"
                    label="Ваш e-mail">
                    <a-input 
                        v-model="form.email"
                        size="large"
                        @pressEnter="captchaCheck()"
                        placeholder="Введите e-mail" />
                </a-form-model-item>
                <a-form-model-item
                    class="mb-2"
                    prop="password"
                    label="Ваш пароль">
                    <a-input-password 
                        v-model="form.password"
                        size="large"
                        @pressEnter="captchaCheck()"
                        placeholder="Введите пароль" />
                </a-form-model-item>
                <a-button
                    type="primary"
                    :loading="loading"
                    class="mb-2"
                    size="large"
                    ghost
                    block
                    @click="captchaCheck()">
                    Войти на портал
                </a-button>
                <router-link
                    :to="{name: 'forgotPassword'}"
                    class="text_current text-center block py-4">
                    {{ $t('forgot_your_password') }}
                </router-link>
            </div>
        </a-form-model>
        <div class="info">
            <h1 class="title less_breakpoint_1">
                Облачный продукт «КОННЕКТ» платформы gos24.kz
            </h1>
            <p class="description mb-5">
                Современная и профессиональная система для грамотного планирования и эффективного управления задачами государственных учреждений и предприятий различных форм собственности
            </p>
            <p class="description">
                Отечественная программная разработка, имеющая подтверждение на соответствие нормам информационной безопасности РК
            </p>
        </div>
    </div>
</template>

<script>
import eventBus from '@/utils/eventBus'
import { mapState } from 'vuex'
const regexEmail = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

export default {
    computed: {
        ...mapState({
            windowWidth: state => state.windowWidth
        }),
        drawerWidth() {
            if (this.windowWidth > 1024) 
                return 936
            if (this.windowWidth > 828)
                return 828
            return this.windowWidth
        },
        authConfig() {
            return this.$store.state.user.authConfig
        },

    },
    data() {
        return {
            loading: false,
            form: {
                typeInput: '',
                password: ''
            },

            authRules: {
                email: [
                    { required: true, message: this.$t('field_required'), trigger: 'change' },
                    { type: 'email', message: 'Введите корректный email', trigger: 'change' },
                    { max: 255, message: this.$t('required_sym', { sym: 255 }), trigger: 'change' },
                ],
                password: [
                    { required: true, message: this.$t('field_required'), trigger: 'change' },
                    { min: 6, message: this.$t('required_sym', { sym: 6 }), trigger: 'change' }
                ]
            },
        }
    },
    created() {
        if (this.$route.name === 'joinUser' && this.$route.query.token) {
            this.$store.commit('user/SET_REG_STEP', 2)
            this.$http.post(`/users/my_organizations/get_contractor_by_invite_token/`, {
                token: this.$route.query.token
            })
                .then(({ data }) => {
                    this.openRegistrationDrawer(data)
                    this.$nextTick(() => {
                        eventBus.$emit('set_contractor_for_register', { contractor: data })
                    })
                    console.log(data)
                })
                .catch(error => console.error(error))
        }
    },
    methods: {
        openRegistrationDrawer(data=null) {
            eventBus.$emit('open_registration', { contractor: data})
        },
        captchaCheck() {
            if(this.authConfig.reCAPTCHASiteKey) {
                grecaptcha.ready(() => {
                    this.loading = true
                    grecaptcha.execute(this.authConfig.reCAPTCHASiteKey, {action: 'login'}).then((token) => {
                        this.formSubmit(token)
                    })
                })
            } else {
                this.formSubmit()
            }
        },
        formSubmit(captcha = null) {
            this.$refs.authForm.validate(async valid => {
                if (valid) {
                    try {
                        this.loading = true
                        const target = regexEmail.test(this.form.typeInput) ? 'email' : 'phone'
                        let login = JSON.parse(JSON.stringify(this.form.typeInput)).trim()
                        let formData = JSON.parse(JSON.stringify(this.form))

                        if(target === 'email') {
                            formData.email = login
                        }
                        if(target === 'phone') {
                            formData.phone = login
                        }

                        if(captcha)
                            formData.captcha = captcha
                        else
                            formData.captcha = 'captcha'

                        if(target === 'phone' && !login.includes('+')) {
                            const fLetter = login.charAt(0)
                            if(fLetter !== '8') {
                                formData.phone = `+${login}`
                            } else {
                                login = login.substring(1)
                                formData.phone = `+7${login}`
                            }
                        }

                        const data = await this.$store.dispatch('user/login', formData)
                        if(data) {
                            localStorage.setItem('is_show_offer', data.user_previous_login === null)
                            await this.$store.dispatch('loginConfigInit')
                            // await this.$store.dispatch('navigation/routeInit')
                            // await this.$store.dispatch('loginAppInit')
                            location.reload()
                            // this.$router.push({ name: 'meetings' })
                        }
                    } catch(error) {
                        if(error?.status) {
                            this.$message.error(error.status, 5)
                        } else {
                            if(error?.message) {
                                this.$message.error(error.message, 5)
                            } else {
                                this.$message.error(this.$t('authorisation_error'))
                            }
                        }
                        console.log(error)
                    } finally {
                        this.loading = false
                    }
                } else {
                    return false
                }
            })
        },

    }
}
</script>

<style lang="scss" scoped>
$breakpoint-1: 1279px;
$breakpoint-2: 1024px;

.form_col_span_2 {
    grid-column: span 2 / span 2;
}
.grid_step_2_three_col {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1.5rem;
}
.grid_step_2 {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1.5rem;
}
.steps {
    display: flex;
    justify-content: space-between;
    @media (max-width: 680px) {
        flex-direction: column-reverse;
        .steps_list {
            margin-bottom: 1.5rem;
        }
    }
}
.custom_buttons_grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1.5rem;
    @media (max-width: 828px) {
        grid-template-columns: repeat(2, 1fr);
    }
}
.next_button {
    grid-column: span 2 / span 2;
    @media (max-width: 828px) {
        grid-column: span 1 / span 1;
    }
}
.auth {
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;    
    &::v-deep {
        .ant-input,
        .ant-btn {
            height: 50px;
        }
    }
    @media (max-width: $breakpoint-1) {
        flex-direction: column;
    }
}
.form {
    padding: 30px 40px;
    background: #ffffffb2;
    border-radius: 20px;
    margin-top: auto;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    
    @media (max-width: $breakpoint-1) {
        max-width: 716px;
        width: 100%;
        margin: 0 auto;
        margin-bottom: 50px;
    }

    @media (max-width: $breakpoint-2) {
        padding: 30px 30px;

        max-width: auto;
        width: 100%;
    }
}
.form_inner {
    width: 500px;
    margin: 0 auto;
    @media (max-width: $breakpoint-2) {
        max-width: auto;
        width: 100%;
    }

}
.form_title {
    // font-size: calc(76px - 1024px / 100vw);
    // font-size: calc(60px + 16 * (100vw / 1024));
    font-size: 2rem;
    white-space: nowrap;
    color: #0C0C0E;
    @media (max-width: $breakpoint-2) {
        white-space: normal;
        line-height: 1.5em;
    }
}
.info {
    max-width: 900px;
    margin-left: 5rem;

    color: #fff;
    @media (max-width: $breakpoint-1) {
        max-width: 716px;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
    }
}
.title {
    color: #fff;
    line-height: 1.2;
    margin-bottom: 40px;
    font-size: 76px;
    @media (max-width: 1920px) {
        margin-bottom: calc(8px + 32 * (100vw / 1920));
        font-size: calc(16px + 60 * (100vw / 1920));
    }
    @media (max-width: calc($breakpoint-2 - 1px)) {
        font-size: 36px;
        text-align: center;
    }
}
.description {
    margin-left: calc(4px + 96 * (100vw / 1920));

    // font-size: 1.5rem;
    font-size: calc(14px + 6 * (100vw / 1920) );


    @media (max-width: $breakpoint-1) {
        max-width: 716px;
        margin-left: 0;
        // margin-right: auto;
    }
    @media (max-width: calc($breakpoint-2 - 1px)) {
        font-size: 20px;
        text-align: center;
    }
}
</style>
